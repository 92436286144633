* {
  font-family: "poppins" !important;
}

.skyblue {
  background-color: white !important;
}

.White {
  color: white !important;
}

.blue {
  color: #fe6f35;
}

.bgBlue {
  background-color: #fe6f35 !important;
  color: white !important;
}

.bgGreen {
  background-color: #8aaf0f !important;
  color: white !important;
}

.marginTop {
  margin-top: 150px !important;
}

h6 {
  font-size: 1.25rem;
}

.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 1rem;
}

.font_14 {
  font-size: 14px;
}

.ant-drawer-body {
  background-color: #242524 !important;
}
.ant-drawer-body > div:hover {
  background-color: #f8966f !important;
}

.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.sidebar {
  z-index: 30;
  margin: 0;
  padding: 0;
  width: 260px;
  background-color: #242524;
  position: fixed;
  color: white;
  height: 100%;
  overflow: auto;
}
.ant-btn-primary {
  background: #fe6f35 !important;
  border-color: #fe6f35 !important;
}
.btn-primary {
  background: #fe6f35 !important;
  border-color: #fe6f35 !important;
}
.ant-btn-dangerous {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.ant-pagination-item-active {
  border-color: #fe6f35 !important;
}
.sidebar a {
  display: block;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #f8966f;
  color: white;
}

navLinking .sidebar a:hover,
a:active {
  background-color: #f8966f;
  color: white;
}
.sidebar .nth:hover,
.nth:active {
  background-color: transparent !important;
  color: white;
}

div.content {
  margin-left: 250px;
  padding: 1px 16px;
  min-height: calc(100vh - 50px);
  max-height: fit-content;
  margin-bottom: 60px;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0 !important;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .sidebtn {
    display: none !important;
  }
}

.btn:focus {
  box-shadow: 0 0 0 rgb(0 123 255 / 25%) !important;
}

.displayFlex {
  display: flex;
  justify-content: space-between;
}

.marginLeft_2 {
  margin-left: 2.5rem;
}

.borderLeft {
  border-left: 2px solid #fe6f35 !important;
}

.table td,
.table th {
  border-top: none !important;
  padding: 0% !important;
}

.textCenter {
  text-align: center;
}

.img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  left: 120px;
  z-index: 100;
  background-color: red;
  padding: 5px 2px 2px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}

.img-wrap:hover .close {
  opacity: 1;
}

.ant-table-column-sorters {
  font-weight: bold !important;
}

.ant-pagination-item-link {
  background-color: #fe6f35 !important;
  color: white !important;
}

/* .ant-table-pagination{
  display: none !important;
} */
.ant-table-column-sorter-inner {
  color: #fe6f35 !important;
}

.ant-input,
.ant-select {
  border: 1px solid #e8f2ff !important;
  background-color: #fafafa !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.ant-table table {
  padding: 8px !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px !important;
}

.ant-table-thead > tr > th {
  border-bottom: 0px !important;
}

.ant-table-wrapper {
  border-radius: 8px !important;
  border: 1px solid #f4f7f9;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: red !important;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-item {
  margin: 3px !important;
  /* height: 28px !important; */
}

/* .ant-pagination.mini .ant-pagination-item{
  height: 27px !important;
  margin:5px !important;
  line-height: 24px !important;
} */
.lightBlue {
  background-color: #f4f7f9;
}

.offBlue {
  background-color: white;
}

.Light {
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #cbe3f3;
}

.Radius2 {
  border-radius: 2px;
  border-color: #fe6f35;
}

.Radius8 {
  border-radius: 8px !important;
  background-color: #fe6f35 !important;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.fillColor {
  background-color: #fafafa;
  border: 1px solid #e8f2ff;
  outline: none;
  border-radius: 8px;
}

.outline {
  outline: none;
}

.divider {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  color: #fe6f35;
  border: 0.5px solid;
}

.centerItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card{
  border: none !important;
} */
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  background-color: #f5f5f5;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon.apexcharts-selected {
  display: none;
}
tr:nth-child(even) {
  background-color: #fafafa !important;
}
.anticon.anticon-left svg {
  margin-bottom: 0.4rem !important;
}
.anticon.anticon-right svg {
  margin-bottom: 0.4rem !important;
}
/* .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
  min-height: 300px !important;
} */

.dropdown-menu {
  /* left: -7rem !important;
  transform: translate3d(-10px, 48px, 0px) !important; */
  width: 100% !important;
  top: 12px !important;
}

.pl2 {
  padding-left: 2rem !important;
}

.navLinking.active {
  background-color: transparent !important;
}

.ant-btn-default {
  color: white !important;
  border-color: #fe6f35 !important;
  background-color: #fe6f35 !important;
}

.blue:focus-visible {
  outline-color: #fe6f35 !important;
}

.bodeerr {
  border-radius: 8px !important;
  border: 1px solid #d1d7d9 !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.w-5 {
  width: 20px !important;
  height: 20px !important;
}

.userLastName {
  display: flex;
  min-width: 100px ;
}